import React from "react";
import young from "../assets/youngme2.jpg";
import argyle from "../assets/argyle2.jpg";
import uni from "../assets/unirs.jpg";
import qvc from "../assets/qvcrs.jpg";
import hobbiton from "../assets/hobbitonrs.jpg";
import working from "../assets/workingrs.jpg";

const About = () => {
  return (
    <div className="about-page">
      <div className="first-section">
        <div className="picture-section">
          <img src={young} alt="young James" />
        </div>
        <div className="text-section">
          <h4>The Early Years</h4>
          <p>
            From a young age, I've always been interested in solving problems. I
            liked to challenge myself with games like sudoku, had frequent chess
            battles against my father (I always won, if my father says any
            different then he's lying), and recently I taught himself how to
            solve a rubik's cube to impress a lady (who is now my fiance).
          </p>
        </div>
        <div className="right-triangle"></div>
      </div>
      <div className="second-section">
        <div className="left-triangle"></div>
        <div className="text-section">
          <h4>Growing Up</h4>
          <p>
            I also had an interest in the film and television industry, and so
            decided to leave school at 16, choosing to study a BTEC in film at
            college rather than do my A-Levels (a move that was very unpopular
            with my parents at the time).
          </p>
        </div>
        <div className="picture-section">
          <img src={argyle} alt="James as cameraman" />
        </div>
      </div>
      <div className="third-section">
        <div className="picture-section">
          <img src={uni} alt="James at university" />
        </div>
        <div className="text-section">
          <h4>Education</h4>
          <p>
            My gamble paid off, however, as I finished my course with a Triple
            Distinction, and from there went on to Bournemouth University's
            highly rated Film and Television Production course, where I
            graduated with a 2:1 and was able to secure full time work before
            finishing my course (although writing a dissertation on the coach
            between London and Bournemouth was less than ideal).
          </p>
        </div>
        <div className="right-triangle"></div>
      </div>
      <div className="fourth-section">
        <div className="left-triangle"></div>
        <div className="text-section">
          <h4>Working in Television</h4>
          <p>
            I worked in television for many years, eventually training to become
            a graphics operator at QVC. After a while I decided to spread my
            wings, and worked on a wide range of channels, shows, and events
            (including Channel 4's recent award winning Paralympic coverage).
            However, I started to realise that the television industry wasn't
            satisfying my creative problem solving urges.
          </p>
        </div>
        <div className="picture-section">
          <img src={qvc} alt="James on television" />
        </div>
      </div>
      <div className="fifth-section">
        <div className="picture-section">
          <img src={working} alt="James working in television" />
        </div>
        <div className="text-section">
          <h4>A Change in Direction</h4>
          <p>
            {" "}
            I returned to QVC as the manager of my former team to help rebuild
            after tremendous turnover, and to get things back on track. I
            enjoyed this challenge, but still knew my interests lay elsewhere.
            In 2021 I signed up for General Assembly's Immersive Software
            Engineering course, and started my new adventure as a software
            engineer.
          </p>
        </div>
        <div className="right-triangle"></div>
      </div>
      <div className="sixth-section">
        <div className="left-triangle"></div>
        <div className="text-section">
          <h4>But who is James?</h4>
          <p>
            As for who I am as a person? I'm a social fellow who enjoys making
            people laugh, but I do feel somewhat awkward writing about my life
            for this page. I still enjoy discussing films and television shows,
            as well as music and gaming. I have a keen interest in what is
            widely referred to as 'nerdy things', like Star Wars, Lord of the
            Rings, and comic books.
          </p>
        </div>
        <div className="picture-section">
          <img src={hobbiton} alt="James at Hobbiton" />
        </div>
      </div>
    </div>
  );
};

export default About;
