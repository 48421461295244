import "./styles/App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Nav from "./components/Nav";
import Projects from "./Pages/Projects";
import logo from "./assets/avatartransparent.png";
import Contact from "./Pages/Contact";
import Skills from "./Pages/Skills";

function App() {
  return (
    <Router>
      <header className="app-header">
        <img src={logo} alt="avatar of James Rogers" />
        <h1>
          <span className="const">const</span>{" "}
          <span className="name">jamesRogers</span>
          <span className="equals"> =</span>{" "}
          <span className="result">juniorSoftwareEngineer</span>
          <span className="typing">_</span>
        </h1>
      </header>
      <nav>
        <Nav />
      </nav>
      <main>
        <div className="page-container">
          <div className="content-wrap">
            <Routes>
              <Route path="/about" element={<About />}></Route>
              <Route path="/projects" element={<Projects />}></Route>
              <Route path="/contact" element={<Contact />}></Route>
              <Route path="/skills" element={<Skills />}></Route>
              <Route exact path="/" element={<Home />}></Route>
            </Routes>
          </div>
        </div>
      </main>
    </Router>
  );
}

export default App;
