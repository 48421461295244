import React from "react";
import SkillCard from "../components/SkillCard";
import html from "../assets/logos/html2.png";
import css from "../assets/logos/css2.png";
import javascript from "../assets/logos/JavaScript-logo.png";
import react from "../assets/logos/reactlogo.png";
import sass from "../assets/logos/sasslogo3.png";
import python from "../assets/logos/python.png";
import express from "../assets/logos/express.png";
import nodejs from "../assets/logos/node.png";
import mongo from "../assets/logos/mongo.png";
import django from "../assets/logos/django.png";
import rest from "../assets/logos/restapi.png";
import postgre from "../assets/logos/postgresql.png";
import git from "../assets/logos/git.png";
import github from "../assets/logos/github.png";
import npm from "../assets/logos/npm.png";
import yarn from "../assets/logos/yarn.png";
import heroku from "../assets/logos/heroku.jpg";
import insomnia from "../assets/logos/insomnia.png";
import postman from "../assets/logos/postman.png";
import slack from "../assets/logos/slack.png";
import trello from "../assets/logos/trello.png";

const Skills = () => {
  return (
    <div className="skills">
      <h2>
        So far on my software engineering journey, I have picked up the
        following skills:
      </h2>
      <h3>Front-End:</h3>
      <div className="skill-list">
        <SkillCard image={html} title="HTML" />
        <SkillCard image={css} title="CSS" />
        <SkillCard image={javascript} title="JavaScript" />
        <SkillCard image={react} title="React" />
        <SkillCard image={sass} title="SASS" />
      </div>
      <h3>Back-End:</h3>
      <div className="skill-list">
        <SkillCard image={python} title="Python" />
        <SkillCard image={express} title="Express" />
        <SkillCard image={nodejs} title="Node.js" />
        <SkillCard image={mongo} title="MongoDB" />
        <SkillCard image={django} title="Django" />
        <SkillCard image={rest} title="REST Framework" />
        <SkillCard image={postgre} title="PostgreSQL" />
      </div>
      <h3>Other:</h3>
      <div className="skill-list">
        <SkillCard image={git} title="Git" />
        <SkillCard image={github} title="GitHub" />
        <SkillCard image={npm} title="npm" />
        <SkillCard image={yarn} title="Yarn" />
        <SkillCard image={heroku} title="Heroku" />
        <SkillCard image={insomnia} title="Insomnia" />
        <SkillCard image={postman} title="Postman" />
        <SkillCard image={slack} title="Slack" />
        <SkillCard image={trello} title="Trello" />
      </div>
    </div>
  );
};

export default Skills;
