import React from "react";

const SkillCard = ({ image, title }) => {
  return (
    <div className="skill">
      <div className="logo">
        <img src={image} alt={`${title} logo`} />
      </div>
      <h4>{title}</h4>
    </div>
  );
};

export default SkillCard;
