import React from "react";

const Contact = () => {
  return (
    <div className="contact">
      <h4>If you'd like to get in touch, you can reach me at:</h4>
      <div className="details">
        <div className="email">
          <i class="far fa-envelope"></i>
          <p>james92rogers@gmail.com</p>
        </div>
      </div>
      <h4>Alternatively, you can reach me on any of the platforms below:</h4>
      <div className="icons">
        <a
          href="https://www.linkedin.com/in/james92rogers/"
          target="_blank"
          rel="noreferrer"
        >
          <i class="fab fa-linkedin"></i>
        </a>
        <a
          href="https://github.com/james92rogers"
          target="_blank"
          rel="noreferrer"
        >
          <i class="fab fa-github"></i>
        </a>
        <a
          href="https://medium.com/@james92rogers"
          target="_blank"
          rel="noreferrer"
        >
          <i class="fab fa-medium"></i>
        </a>
      </div>
    </div>
  );
};

export default Contact;
