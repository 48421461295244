import React from "react";
import battleships from "../assets/battleships.png";
import footprint from "../assets/footprintSS.png";
import onedayin from "../assets/onedayin.png";
import spotter from "../assets/spotter.png";

const Projects = () => {
  return (
    <div className="projects">
      <div className="current">
        <div className="ticker-light"></div>
        <div className="current-projects">
          <h4>Currently Working On:</h4>
          <ul>
            <li>Homework App for Schools</li>
            <li>Rogers' Movie Blog</li>
          </ul>
        </div>
      </div>
      <div className="project">
        <h2>Spotter</h2>
        <div className="content">
          <div className="image">
            <img src={spotter} alt="screenshot of spotter app" />
          </div>
          <div className="desc">
            <ul>
              <li>Final solo project with 9 day time limit</li>
              <li>
                Tasked to make a full stack application using Python and Django
                in the backend
              </li>
              <li>
                Created a full social media site based around users searching
                for friends to work out with
              </li>
              <li>Included messaging functionality as an extra bonus</li>
              <li>
                Visit the site{" "}
                <a
                  href="https://bit.ly/jrspotter"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </li>
              <li>
                Read more about it{" "}
                <a
                  href="https://github.com/james92rogers/Spotter"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="project">
        <h2>One Day In</h2>
        <div className="content">
          <div className="desc">
            <ul id="onedayin-text">
              <li>Group project in teams of 3</li>
              <li>Had 9 days to create a full MERN Stack application</li>
              <li>
                Created a travel website aimed at users spending one day in a
                city
              </li>
              <li>
                Worked across both the back and front ends to create
                functionality for the site to ensure a smooth final product
              </li>
              <li>
                Visit the site{" "}
                <a
                  href="https://bit.ly/jronedayin"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </li>
              <li>
                Read more about it{" "}
                <a
                  href="https://github.com/james92rogers/OneDayIn"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </li>
            </ul>
          </div>
          <div className="image">
            <img
              src={onedayin}
              alt="screenshot of One Day In"
              id="onedayin-image"
            />
          </div>
        </div>
      </div>
      <div className="project">
        <h2>footprint</h2>
        <div className="content">
          <div className="image">
            <img src={footprint} alt="screenshot of footprint app" />
          </div>
          <div className="desc">
            <ul>
              <li>Paired project with 48 hour time limit</li>
              <li>
                Tasked to make a front-end application to pair with an existing
                public API
              </li>
              <li>Front end must be built using React</li>
              <li>
                Created site for users to track the carbon footprint of their
                journeys
              </li>
              <li>
                Visit the site{" "}
                <a
                  href="https://bit.ly/jrfootprint"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </li>
              <li>
                Read more about it{" "}
                <a
                  href="https://github.com/james92rogers/footprint"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="project">
        <h2>Battleships</h2>
        <div className="content">
          <div className="desc">
            <ul id="battleship-text">
              <li>Solo project to make a game in 9 days</li>
              <li>
                Game must be grid based and made using HTML, CSS, and JavaScript
              </li>
              <li>Player must be able to play against the computer</li>
              <li>
                Added extra functionality so the computer picked moves
                tactically depending on previous move results
              </li>
              <li>
                Play the game{" "}
                <a
                  href="https://bit.ly/jrbattleships"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </li>
              <li>
                Read more about it{" "}
                <a
                  href="https://github.com/james92rogers/Battleships"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </li>
            </ul>
          </div>
          <div className="image">
            <img
              src={battleships}
              alt="screenshot of battleship game"
              id="battleship-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
