import React from "react";

const Home = () => {
  return (
    <div className="home">
      <div className="quotes">
        <h4 className="quote-1">
          "I just met him, but he seems like a nice enough guy."
        </h4>
        <h4 className="quote-2">
          "James is probably my favourite junior software engineer, and my
          second favourite son."
        </h4>
        <h4 className="quote-3">"Stop asking me for a quote"</h4>
      </div>
      <h3>Hello, and welcome to my site</h3>
      <h4 className="intro">A little about me...</h4>
      <p>
        After working for several years in the television industry as a graphics
        operator and then manager, I found myself wanting something more to
        satisfy my creative urges. I’ve always had a passion for technology as
        well as problem solving, from simple games to completing a Rubik’s Cube,
        and so I left my career and enrolled on General Assembly’s intensive
        software engineering course. I bring a positive attitude, hard work
        ethic, and eagerness to improve to everything I do. I’m now looking for
        a new role where I can use my new skillset to flex my creative passions
        while also continuing to develop and grow as a software engineer.
      </p>
      <h4>
        Feel free to browse my site for a look at some of the projects I've made
        as well as more information about my background. If you have any
        questions, don't hesitate to get in touch
      </h4>
    </div>
  );
};

export default Home;
